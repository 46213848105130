.card-building {
  .count-title {
    font-weight: bold;
    text-align: center;
  }
  .items-wrap {
    //display: flex;
    padding: 0 10px;
    border-top: $border;
    border-bottom: $border;
    .item {
      width: 25%;
    }
    b, span {
      display: block;
    }
  }
}