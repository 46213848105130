.knm-popup {
  width: 420px;

  .leaflet-popup-content {
    width: 97% !important;
    margin: 5px 0 5px 5px;
  }

  .leaflet-popup-close-button {
    background-color: #fff !important;
    border-radius: 50%;
    top: -7px !important;
    right: -7px !important;
  }

  .ant-card-head {
    min-height: 40px;
  }

  .ant-card-head-title {
    font-weight: bold;
    padding: 8px 0;
  }

  .ant-card-extra {
    padding: 8px 0;
  }

  .ant-card-type-inner .ant-card-head {
    padding: 0 10px;
  }

  .ant-card-type-inner {
    .ant-card-body {
      p {
        margin: 0;
        font-size: 0.95em;
      }
    }
  }

  .ant-card-body {
    padding: 0;
    font-size: 0.8em;

    .line-top {
      padding: 5px;
      display: flex;
      border-bottom: $border;

      .item {
        flex-grow: 1;
      }

      b {
        display: block;
      }

      hr {
        background-color: #eee;
      }

      span {
        display: block;
      }

    }

    .line {
      padding: 5px;
      display: flex;
      border-bottom: $border;

      b {
        width: 50%;
        display: block;
      }

      span {
        display: block;
      }
    }

    .line-bottom {
      padding: 5px;
      display: flex;
      border-bottom: $border;

      b {
        min-width: 70%;
        text-align: right;
      }

      span {
        padding-left: 10px;
      }
    }

    .boilers {
      .boilers-title {
        text-align: center;
        font-weight: bold;
        border-bottom: $border;
        padding: 5px 0;
        .alert {
          font-size: 0.9em;
          color: $color-text-alert;
          background-color: $color-alert;
          border-radius: 8px;
          padding: 3px 7px;
          margin-left: 5px;
          &.d-none {
           display: none;
          }
        }
      }

      .boiler-list {
        list-style: none;

        border-bottom: $border;
        margin: 5px 0;
        padding-left: 0;
        .boiler-item {
          position: relative;
          padding-left: 40px;

          &.no-work {

            background-color: $color-alert;
            color: $color-text-alert;
          }
          .count {
            position: absolute;
            left: 10px;
            top:8px;
            background-color: #fff;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            font-weight: bold;
          }
          b {
            margin-right: 5px;
          }

          .boiler-data {
            margin-right: 5px;
          }
        }
      }
    }
  }
}
